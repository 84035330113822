import React from 'react';
import { InputProps } from './input-props';
import { StyledInput } from './input-styles';

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref): React.ReactElement => {
    const {
      size = 'medium',
      colorScheme = 'light',
      onBlur,
      onFocus,
      onChange,
      disabled,
      readOnly,
      placeholder,
      isError,
      variant = 'default',
      ...rest
    } = props;
    return (
      <StyledInput
        ref={ref}
        {...rest}
        {...{ size, colorScheme, onBlur, onFocus, onChange, disabled, readOnly, placeholder, isError, variant }}
      />
    );
  },
);

Input.displayName = 'Input';
