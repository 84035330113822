import { COLORS, SPACING } from '@cheese-fondue/styles';
import { FONTS } from '@cheese-fondue/styles/theme-constants';
import { typography } from '@cheese-fondue/styles/typography';
import { tint } from 'polished';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { InputProps } from './input-props';

export const colors: { [T in InputProps['colorScheme']]: string } = {
  light: COLORS.blue400,
  dark: COLORS.orange200,
};

const getSizeStyles = ({ size = 'medium' }: InputProps): FlattenSimpleInterpolation => {
  const sizes = {
    small: css`
      height: 32px;
      font-size: ${typography.pSmall};
    `,
    medium: css`
      height: 40px;
      font-size: ${typography.pMedium};
    `,
    large: css`
      height: 48px;
      font-size: ${typography.pMedium};
    `,
  };

  return sizes[size];
};

const getVariantStyles = ({
  variant = 'default',
  colorScheme = 'light',
  isError,
}: InputProps): FlattenSimpleInterpolation => {
  const colors = {
    light: {
      default: css`
        color: ${isError ? COLORS.red : COLORS.blue400};
        border-color: ${isError ? COLORS.red : tint(0.6, COLORS.blue400)};

        &:active,
        &:focus {
          border-color: ${isError ? COLORS.red : COLORS.blue400};
          color: ${isError ? COLORS.red : COLORS.blue400};
        }

        &::placeholder {
          color: ${isError ? COLORS.red : tint(0.6, COLORS.blue400)};
        }

        &[disabled] {
          border-color: ${tint(0.6, COLORS.blue400)};
        }
      `,
      filled: css`
        color: ${isError ? COLORS.red : COLORS.blue400};
        border-color: ${isError ? COLORS.red : tint(0.6, COLORS.blue400)};

        &:active,
        &:focus {
          border-color: ${isError ? COLORS.red : COLORS.blue400};
          color: ${isError ? COLORS.red : COLORS.blue400};
        }

        &::placeholder {
          color: ${isError ? COLORS.red : tint(0.6, COLORS.blue400)};
        }

        &[disabled] {
          border-color: ${tint(0.6, COLORS.blue400)};
        }
      `,
    },
    dark: {
      default: css`
        color: ${isError ? COLORS.red : COLORS.orange100};
        border-color: ${isError ? COLORS.red : COLORS.orange200};

        &:active,
        &:focus {
          border-color: ${isError ? COLORS.red : COLORS.orange300};
          color: ${isError ? COLORS.red : COLORS.orange100};
        }

        &::placeholder {
          color: ${isError ? COLORS.red : tint(0.6, COLORS.blue400)};
        }

        &[disabled] {
          border-color: ${COLORS.orange200};
        }
      `,
      filled: css`
        background-color: ${COLORS.orange100};
        color: ${isError ? COLORS.red : COLORS.blue400};
        border-color: ${isError ? COLORS.red : COLORS.orange200};

        &:active,
        &:focus {
          border-color: ${isError ? COLORS.red : COLORS.orange300};
          color: ${isError ? COLORS.red : COLORS.blue400};
        }

        &::placeholder {
          color: ${isError ? COLORS.red : tint(0.6, COLORS.blue400)};
        }

        &[disabled] {
          border-color: ${COLORS.orange200};
        }
      `,
    },
  };

  const styles = {
    default: css`
      border: none;
      border-bottom: 2px solid;
      background: transparent;
      padding: 0 0 ${SPACING.one * 0.25}px 0;
      ${colors[colorScheme][variant]}
    `,
    filled: css`
      border: 2px solid;
      border-radius: 8px;
      padding: 0 ${SPACING.one}px;
      display: flex;
      align-items: center;
      ${colors[colorScheme][variant]}
    `,
  };
  return styles[variant];
};

export const StyledInput = styled.input<InputProps>`
  box-shadow: none;
  width: 100%;
  margin: 0;
  font-family: ${FONTS.primary};
  line-height: 100% !important;

  ${props => getVariantStyles(props)}
  ${props => getSizeStyles(props)}

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
