import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import Favicons from './favicons';
import OGTags from './ogTags';

export default ({ location, pageName, children, pageContext }) => {
  const queryData = useStaticQuery(siteMetadataQuery);

  const seo = getSeoTags(pageName, queryData, pageContext, location);

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={queryData.siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1, viewport-fit=cover" />
        {children}
      </Helmet>
      <Favicons />
      <OGTags url={seo.url} title={seo.title} description={seo.description} ogImage={seo.ogImage} />
    </>
  );
};

const getSeoTags = (pageName, queryData, pageContext, location) => {
  const {
    site: {
      siteMetadata: { defaultTitle, defaultDescription, ogImage, siteUrl },
    },
  } = queryData;
  const { pathname } = location;
  return {
    title: pageName ? `${pageName} - ${defaultTitle}` : `${defaultTitle} | ${defaultDescription}`,
    description: defaultDescription,
    ogImage,
    url: siteUrl + (pathname || ''),
  };
};

export const siteMetadataQuery = graphql`
  query SiteMetaData {
    site {
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        siteLanguage
        ogImage
      }
    }
  }
`;
