import { withPrefix } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

const Favicon = () => (
  <Helmet>
    <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('icons/apple-touch-icon.png')} />
    <link rel="icon" sizes="32x32" href={withPrefix('slides-static/new-favicon.png')} />
    <link rel="mask-icon" href={withPrefix('icons/safari-pinned-tab.svg')} color="#5bbad5" />
    <meta name="apple-mobile-web-app-title" content="Delphia" />
    <meta name="application-name" content="Delphia" />
    <meta name="msapplication-TileColor" content="#53adc1" />
  </Helmet>
);

export default Favicon;
