import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

const OGTags = ({ url, title, description, ogImage }) => (
  <Helmet>
    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    {/* FB caches images based on the url - may need to be kicked if the image changes. See https://developers.facebook.com/docs/sharing/webmasters */}
    {/* Don't forget to update the fb image on the blog as well: https://delphia.ghost.io/ghost/#/settings/general */}
    <meta property="og:image" content={ogImage} />
    <meta property="og:image:secure_url" content={ogImage} />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:type" content="image/png" />
    <meta name="twitter:card" content={description} />
  </Helmet>
);

export default OGTags;

OGTags.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ogImage: PropTypes.string.isRequired,
};
